import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Education = ({ data, location  }) => (
  <Layout>
    <SEO
      pagetitle="プログラミング教育ソリューション・製品"
      pagedesc="プログラミング教育ソリューション及び取扱製品情報"
      pagepath={location.pathname}
    />

    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.edu_solution.childImageSharp.fluid}
          alt="プログラミング教育関連ソリューションを提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          Education <span>Solution</span>
        </h2>
        <br />
        <br />
        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.edu_solution_pct01.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>教材・カリキュラムの提案</h3>
              <p>
                世界中で人気の教材を国内最多の水準で多様な種類を扱っており、
                様々な需要に応えることができます。
                また、学校等の既存カリキュラムに合わせて、
                教材のカスタマイズや作成を行うこともできます。
                その他、ご要望に応じてお客様と共にカリキュラムを検討することも可能です。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.edu_solution_pct02.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>STEAM LABの提案</h3>
              <p>
                プログラミング教育の浸透に合わせて、各種教材や機材を適切に扱える場所が必要になります。
                学校におけるSTEAM教育にも対応できるLABをお客様とご一緒にお作りいたします。 
                世界の先進事例調査も含め、LABに必要なモノやサポートをまとめて一括で扱うため、 
                煩雑な手続き等の手間を減らします。  
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.edu_solution_pct03.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>サポートの提案</h3>
              <p>
                プログラミング教育や情報科目の現場では、様々なトラブルが起きる可能性があります。 
                導入初期など、一定期間のサポートが必要とされることも多く、 
                そのニーズに応え、スムーズな教育を推進できるよう、充実したサポートを提供いたします。  
              </p>
          </div>
        </div>
      </div>
    </section>

    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.edu_product.childImageSharp.fluid}
          alt="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          Education <span>Product</span>
        </h2>
        <br />
        <br />
        <div className="details">
          <div className="detailImg">
            <Link to={`https://store.iftiny.com/collections/microbit-and-basic-kits`}>
              <figure>
                <Img fluid={data.edu_product_pct01.childImageSharp.fluid} alt="" />
              </figure>
            </Link>
          </div>
          <div className="detailText">
              <h3>micro:bit基本セット</h3>
              <p>
                micro:bit（マイクロビット）のオリジナルセット製品を提供いたします。
                <br/>
                <Link to={`https://store.iftiny.com/collections/microbit-and-basic-kits`}>
                  <div className="textLink">
                    micro:bit基本セット製品の詳細はこちらです。
                  </div>
                </Link>
                <br/>
                <br/>
                イギリスのBBCが中心となって開発したシングルボードコンピュータのmicro:bitは、
                現在プログラミング教育で最も注目されている製品です。
                当社は、Micro:bit Educational Foundationと協力し、国内正規販売店として
                micro:bitの利用推進と販売を行っています。
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
{/*             <Link to={`/jp/education/product/microbit-related-products/`}> */}
              <figure>
                <Img fluid={data.edu_product_pct02.childImageSharp.fluid} alt="" />
              </figure>
{/*             </Link> */}
          </div>
          <div className="detailText">
              <h3>micro:bit拡張対応製品</h3>
              <p>
                当社では、micro:bitに対応した拡張デバイス、小型ロボット、ブロックロボット、センサーキット等、 
                数多くの製品を取り扱っています。価格帯と種類が豊富なため、各種ご要望にお応えする事が可能です。 
                また、世界でも人気の高いメーカーと直接取引することで、 
                正規販売店として、お求めやすい価格とスピーディな対応が可能です。
{/*                 <Link to={`/jp/education/product/microbit-related-products/`}>
                  <div className="textLink">
                    詳しくはこちら
                  </div>
                </Link> */}
              </p>
          </div>
        </div>

        <div className="details">
          <div className="detailImg">
              <figure>
                <Img fluid={data.edu_product_pct03.childImageSharp.fluid} alt="" />
              </figure>
          </div>
          <div className="detailText">
              <h3>中上級プログラミング製品</h3>
              <p>
                Raspberry pi、Arduino、NVIDIA Jetsonに対応したロボット、モジュール、
                センサーやケース等も取り扱っており、
                高度なプログラミング、AI、IoTの学習に対応する製品もご提供可能です。
                学校や法人様向けでは、ご希望製品の新規取扱もご相談可能です。
              </p>
          </div>
        </div>
      </div>
    </section>




{/*     <section className="children">
      <h2 className="sr-only">children</h2>
      <figure>
        <Img
          fluid={data.children.childImageSharp.fluid}
          alt="プログラミング教育ソリューションを提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="solution">
      <div className="container">
        <h2 className="bar">
          <br />
          Solution <span>Information</span>
        </h2>
      </div>
    </section>
    
    <section>
      <div className="container">
        <h2 className="sr-only">RECENT POSTS</h2>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/jp/info/post/${node.slug}/`}>
                <figure>
                  <Img
                    fluid={node.eyecatch.fluid}
                    alt={node.eyecatch.description}
                    style={{ height: "100%" }}
                  />
                </figure>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </section> */}
  </Layout>
)

export const query = graphql`
  query {
    edu_solution_pct01: file(relativePath: { eq: "edu_solution_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_solution_pct02: file(relativePath: { eq: "edu_solution_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_solution_pct03: file(relativePath: { eq: "edu_solution_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_solution: file(relativePath: { eq: "edu_solution.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    edu_product_pct01: file(relativePath: { eq: "edu_product_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_pct02: file(relativePath: { eq: "edu_product_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_pct03: file(relativePath: { eq: "edu_product_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product: file(relativePath: { eq: "edu_product.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    children: file(relativePath: { eq: "children.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pattern: file(relativePath: { eq: "pattern.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      filter: {category: { elemMatch: { categorySlug: { eq : "solutioninfo" }}}}
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

export default Education
